import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import './assets/styles/common.css';
import { helperMixin } from './mixins/helperMixin'; // Import the mixin
// Import Font Awesome CSS
import 'font-awesome/css/font-awesome.css';
import VueMeta from 'vue-meta';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false
Vue.mixin(helperMixin); // Add the mixin globally

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true // Optional: Refresh metadata on navigation
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCWc7lGeAiAkSGNU_TRwdRJXBSOA3oPWBQ',
  },
  installComponents: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
